<svelte:options tag={null} />

<script lang="ts">
  import { getDevice } from 'rvhelper';
  import { _, setupI18n, addNewMessages, setLocale } from './i18n';
  import { CasinoRandomGameTranslations } from './translations';

  export let endpoint:string = '';
  export let datasource:string = '';
  export let gameevent:string = '';
  export let lang:string = '';
  export let randombuttonicon:string = '';
  export let buttontext: string = '';
  export let translationurl:string = '';
  export let clientstyling:string = '';
  export let clientstylingurl:string = '';
  export let filteredrandomgamecategories:string = '';

  let games:any = [];
  let randomGame:any = '';
  let gamesToShow:any;
  let show:boolean = false;
  let offset:number = 0;
  let count:number = 0;
  let slotDone:boolean = false;

  let userAgent:string = window.navigator.userAgent;
  let customStylingContainer:HTMLElement;

  setupI18n({ withLocale: 'en', translations: {}});

  const setTranslationUrl = ():void => {
    let url:string = translationurl;

    fetch(url).then((res:any) => res.json())
    .then((res) => {
      Object.keys(res).forEach((item:any):void => {
        addNewMessages(item, res[item]);
      });
    }).catch((err:any) => {
      console.log(err);
    });
  }

  Object.keys(CasinoRandomGameTranslations).forEach((item:any) => {
    addNewMessages(item, CasinoRandomGameTranslations[item]);
  });

  const randomizeGame = () => {
    gamesToShow = shuffle(gamesToShow);
    offset = 0;
    count = 0;
    slotDone = false;
    randomGame = games[Math.floor(Math.random() * games.length)];
    show = true;
  }

  const getRandomGame = (refresh?:boolean):void => {
    if(filteredrandomgamecategories){
      do {
        randomizeGame();
      } while(!filteredrandomgamecategories.includes(randomGame.vendor.name));
    } else {
      randomizeGame();
    }

    let interval = setInterval(() => {
      offset -= 75;
      count++;

      if (count == 20) {
        slotDone = true;
        clearInterval(interval);
      }
    }, 150);

    if(refresh){
      //Analytics event
      if(typeof gtag == 'function'){
        gtag('event', 'RefreshRandomGame', {
          'context': 'RandomGameWidget',
          'gameId' : `${randomGame.gId || randomGame.id}`
        });
      } 
    }
    window.postMessage({ type: 'RandomWidgetState', state: true }, window.location.href);
  }

  const shuffle = (array):Array<any> => {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  const playGame = ():void => {
    window.postMessage({ type: gameevent, gameId: randomGame.gId || randomGame.id }, window.location.href);
    show = false;
    window.postMessage({ type: 'RandomWidgetState', state: false }, window.location.href);

    //Analytics event
    if(typeof gtag == 'function'){
      gtag('event', 'OpenGame', {
        'context': 'RandomGameWidget',
        'gameId' : `${randomGame.gId || randomGame.id}`
      });
    }
  }

  const closeModal = ():void => {
    show = false;
    window.postMessage({ type: 'RandomWidgetState', state: show }, window.location.href);
  }

  const fetchGameList = ():void => {
    let url = new URL(`${endpoint}/casino/games`);

    url.searchParams.append('platform', getDevice(userAgent));
    url.searchParams.append('datasource', datasource);
    url.searchParams.append('fields', 'gId,id,href,thumbnail,name,vendor')
    url.searchParams.append('expand', 'vendor');
    url.searchParams.append('pagination', 'offset=0,limit=1000');

    fetch(url.href)
      .then((res:any) => res.json())
      .then((data:any) => {
        games = data.items;
        gamesToShow = games.slice(0, 19);
      });
  }

  const setActiveLanguage = () => {
    setLocale(lang);
  }

  const setClientStyling = ():void => {
    let sheet = document.createElement('style');
    sheet.innerHTML = clientstyling;
    customStylingContainer.appendChild(sheet);
  }

  const setClientStylingURL = ():void => {

    let url:URL = new URL(clientstylingurl);
    let cssFile:HTMLElement = document.createElement('style');

    fetch(url.href)
      .then((res:any) => res.text())
      .then((data:any) => {
        cssFile.innerHTML = data;

        setTimeout(() => { customStylingContainer.appendChild(cssFile) }, 1);
      });
  }

  $: lang && setActiveLanguage();
  $: translationurl && setTranslationUrl();
  $: endpoint && datasource && fetchGameList();
  $: clientstyling && customStylingContainer && setClientStyling();
  $: clientstylingurl && customStylingContainer && setClientStylingURL();
</script>

<div bind:this={customStylingContainer} part="CustomStylingContainer">
    {#if show}
    <div class="ModalWindow" part="ModalWindow">
      <div class="ModalContainer" part="ModalContainer" >
        <span class="ModalCloseBtn" part="ModalCloseBtn" on:click={closeModal} role="button">
          <slot name="close">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
          </slot>
        </span>

        {#if !slotDone}
          <div class="RandomGameWrapper" part="RandomGameWrapper">
            <main class="RandomGamesSliderWrapper" part="RandomGamesSliderWrapper">
              <div class="items" part="items"  style="transform: translateY({offset}px);">
                {#each gamesToShow as game, i}
                  <div class="item" part="item">
                    <img class="RandomGameImage" part="RandomGameImage" src={game.thumbnail} alt={game.name} title={game.name}/>
                  </div>
                {/each}
              </div>
            </main>
            <p class="RandomGameLoading" part="RandomGameLoading">{$_('randomGame.randomGameLoading')}</p>
          </div>
        {/if}

        {#if slotDone}
          <div class="RandomGameWrapper" part="RandomGameWrapper">
            <div class="item" part="item">
              <img class="RandomGameImage" part="RandomGameImage" src={randomGame.thumbnail} alt={randomGame.name} title={randomGame.name}/>
              <button class="RandomTryAgain" part="RandomTryAgain" on:click={() => getRandomGame(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
                  <style type="text/css">
                    .st0{fill:var(--emfe-w-color-white, #FFFFFF);;}
                  </style>
                  <path class="st0" d="M162.4,161.2C145.3,178.5,122.7,187,100,187c-19.1,0-38.2-6.2-54-18.4l2.2,24c0.4,3.6-2.4,6.9-6,7.3h-0.7  c-3.3,0-6.2-2.7-6.7-6l-3.8-41.1c-0.4-3.6,2.4-6.9,6-7.3l40.9-3.8c3.6-0.4,6.9,2.4,7.3,6c0.4,3.6-2.4,6.9-6,7.3l-26,2.4  c29.3,23.3,72.4,21.6,99.5-5.6c19.8-19.8,26.9-48.7,18.4-75.3c-1.1-3.6,0.9-7.3,4.4-8.4c3.6-1.1,7.3,0.9,8.4,4.4  C194,103.9,185.8,137.9,162.4,161.2L162.4,161.2z M47.1,47.9c27.1-27.1,70.2-29.1,99.5-5.6l-26,2.4c-3.6,0.4-6.4,3.6-6,7.3  c0.2,3.6,3.3,6,6.7,6h0.7l40.9-3.8c3.6-0.4,6.4-3.6,6-7.3l-4-41.1c-0.4-3.6-3.6-6.4-7.3-6c-3.6,0.4-6.4,3.6-6,7.3l2.2,24  C119.3,4.4,69.3,6.8,37.6,38.6C14.2,61.9,6,95.9,15.8,127.2c0.9,2.9,3.6,4.7,6.4,4.7c0.7,0,1.3,0,2-0.2c3.6-1.1,5.6-4.9,4.4-8.4  C20.2,96.6,27.4,67.7,47.1,47.9L47.1,47.9z"/>
                </svg>
              </button>
            </div>
            <div class="RandomGameDetails" part="RandomGameDetails">
              <p class="RandomGameTitle" part="RandomGameTitle">{randomGame.name}</p>
              <p class="RandomGameVendor" part="RandomGameVendor">{randomGame.vendor.name}</p>
              <button class="RandomGamePlay" part="RandomGamePlay" on:click={() => playGame()}>{$_('randomGame.playNowRandomGame')}</button>
            </div>
          </div>
        {/if}

      </div>
    </div>
    {/if}
    <div class="RandomButtonWrapper" part="RandomButtonWrapper">
      <button class="RandomButton" part="RandomButton" on:click={() => getRandomGame()}>
        {#if buttontext}
          <span  class="RandomButtonText">{buttontext}</span>
        {:else if !buttontext}
          <span class="RandomButtonText" part="RandomButtonText">{$_('randomGame.playRandomGame')}</span>
        {/if}
        <span class="RandomButtonIcon" part="RandomButtonIcon">
          {@html randombuttonicon}
        </span>
      </button>
    </div>
</div>

<style lang="scss">
  :host {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
  }

  .ModalWindow {
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .9);
    .ModalContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 90vw;
      min-height: 380px;
      max-width: 500px;
      padding: 40px 40px 16px 40px;
      border-top: 3px solid var(--emfe-w-color-secondary, #FD2839);
      border-radius: 5px;
      position: relative;
      background: var(--emfe-w-color-white, #FFFFFF);
    }
    .ModalCloseBtn {
      position: absolute;
      top: 10px;
      right: 10px;
      border-radius: 50%;
      color: var(--emfe-w-color-secondary, #FD2839);
      background: rgba(255, 255, 255, .1);
      cursor: pointer;
      transition: all 150ms ease-in-out;
      width: 28px;
      height: 28px;
      svg {
        width: 28px;
        height: 28px;
      }

      &:hover {
        background: rgba(255, 255, 255, .2);
      }
    }
    .ModalMobileContainer {
      padding: 10px;
      border-top: none;
      .ModalCloseBtn {
        margin: 10px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .RandomGameWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .RandomGamesSliderWrapper {
    height: 170px;
    overflow: hidden;
    transition: transform 400ms ease-in-out;
  }

  .items {
    gap: 5px;
    transition: transform 0.3s ease-in-out;
    transform: translateY(0px);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }

  .RandomGameLoading {
    padding: 30px 0;
  }

  .RandomGameImage {
    width: 256px;
    height: 170px;
    border-radius: 5px;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
  }

  .RandomTryAgain {
    position: absolute;
    bottom: -10px;
    right: -10px;
    background: var(--emfe-w-color-primary, #D0046C);
    color: var(--emfe-w-color-white, #FFFFFF);
    display: flex;
    align-items: center;
    padding: 6px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .item {
    position: relative;
    -webkit-backface-visibility: hidden;
  }

  .RandomGameDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    .RandomGameTitle {
      font-size: 16px;
      line-height: 18px;
      text-align: center;
    }
    .RandomGameVendor {
      font-size: 10px;
      margin-bottom: 10px;
    }
    .RandomGamePlay {
      background: var(--emfe-w-color-primary, #D0046C);
      color: var(--emfe-w-color-white, #FFFFFF);
      border: none;
      border-radius: 5px;
      width: 200px;
      height: 40px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .RandomButtonWrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    .RandomButton {
      display: flex;
      align-items: center;
      gap: 5px;
      background: var(--emfe-w-color-primary, #D0046C);
      color: var(--emfe-w-color-white, #FFFFFF);
      border: none;
      border-radius: 5px;
      padding: 0 30px;
      height: 50px;
      font-size: 16px;
      cursor: pointer;
    }
  }
</style>
