export const CasinoRandomGameTranslations = {
  en: {
    randomGame: {
      playRandomGame: 'Play a random game',
      randomGameLoading: 'Selecting ...',
      playNowRandomGame: 'Play Now'
    }
  },
  zh: {
    randomGame: {
      playRandomGame: '玩隨機遊戲',
      randomGameLoading: '選擇...',
      playNowRandomGame: '現在播放'
    }
  },
  de: {
    randomGame: {
      playRandomGame: 'Spiel ein Zufallsspiel',
      randomGameLoading: 'Auswahl läuf',
      playNowRandomGame: 'Jetzt spielen'
    }
  },
  fr: {
    randomGame: {
      playRandomGame: 'Jouer à un jeu aléatoire',
      randomGameLoading: 'Sélectinner ...',
      playNowRandomGame: 'Jouer maintenant'
    }
  },
  ro: {
    randomGame: {
      playRandomGame: 'Joaca-te un joc aleatoriu',
      randomGameLoading: 'Se selecteaza ...',
      playNowRandomGame: 'Joaca-te Acum'
    }
  },
  tr: {
    randomGame: {
      playRandomGame: 'Rastgele Bir Oyun Oyna',
      randomGameLoading: 'Oyun Seçiliyor',
      playNowRandomGame: 'Şimdi Oyna'
    }
  },
  es: {
    randomGame: {
      playRandomGame: 'Juego aleatorio',
      randomGameLoading: 'Seleccionando…',
      playNowRandomGame: 'Juega Ya'
    }
  },
  pt: {
    randomGame: {
      playRandomGame: 'Jogue um jogo aleatório',
      randomGameLoading: 'Selecionando ...',
      playNowRandomGame: 'Jogue agora'
    }
  },
};
